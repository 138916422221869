// Cookie functions stolen from w3schools
let setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

let getCookie = function(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
(function () {
    'use strict';
    $(function () {
        /*  Cookies alert */
        let cookieAlert = document.querySelector(".cookiealert");
        let acceptCookies = document.querySelector(".acceptcookies");

        cookieAlert.offsetHeight; // Force browser to trigger reflow

        if (!getCookie("acceptCookies")) {
            cookieAlert.classList.add("show");
        }

        acceptCookies.addEventListener("click", function () {
            setCookie("acceptCookies", true, 60);
            cookieAlert.classList.remove("show");
        });
        /* END Cookies alert */
    });
})();
