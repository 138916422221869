/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
global.$ = require('jquery');
// import $ from 'jquery/';
import 'bootstrap';
import './functionality/cookies-alert';


// any CSS you require will output into a single css file (app.css in this case)
import '../css/web.scss';


(function () {
    'use strict';

    if($('body').hasClass('web-home')) {
        $(window).scroll(function () {
            if ($(window).scrollTop() > '60') {
                $('#mainMenu').removeClass('ontop');
            } else {
                $('#mainMenu').addClass('ontop');
            }
        });
    }
})();